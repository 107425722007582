import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Radio, RadioGroup, NotifiedRadioGroup } from '../index';
import { sizes } from './helpers/sizes';
import { radioGroup, logValue } from './helpers/utils';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Radio" title="Radio" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-radio-button"
    }}>{`Basic radio button`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Flex wrap direction=\"row\">\n    <Rhythm m={2}>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio checked name=\"basic\" onChange={logValue} />\n          <Radio name=\"basic\" onChange={logValue} />\n          <Radio checked name=\"basic\" onChange={logValue}>\n            Primary checked\n          </Radio>\n          <Radio name=\"basic\" onChange={logValue}>\n            Primary unchecked\n          </Radio>\n          <Radio checked disabled name=\"basic\" onChange={logValue}>\n            Primary checked disabled\n          </Radio>\n          <Radio disabled name=\"basic\" onChange={logValue}>\n            Primary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio\n            checked\n            name=\"basic\"\n            onChange={logValue}\n            variant=\"secondary\"\n          />\n          <Radio name=\"basic\" onChange={logValue} variant=\"secondary\" />\n          <Radio checked name=\"basic\" onChange={logValue} variant=\"secondary\">\n            Secondary checked\n          </Radio>\n          <Radio name=\"basic\" onChange={logValue} variant=\"secondary\">\n            Secondary unchecked\n          </Radio>\n          <Radio\n            checked\n            disabled\n            name=\"basic\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary checked disabled\n          </Radio>\n          <Radio\n            disabled\n            name=\"basic\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap direction="row" mdxType="Flex">
      <Rhythm m={2} mdxType="Rhythm">
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked name="basic" onChange={logValue} mdxType="Radio" />
            <Radio name="basic" onChange={logValue} mdxType="Radio" />
            <Radio checked name="basic" onChange={logValue} mdxType="Radio">
              Primary checked
            </Radio>
            <Radio name="basic" onChange={logValue} mdxType="Radio">
              Primary unchecked
            </Radio>
            <Radio checked disabled name="basic" onChange={logValue} mdxType="Radio">
              Primary checked disabled
            </Radio>
            <Radio disabled name="basic" onChange={logValue} mdxType="Radio">
              Primary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked name="basic" onChange={logValue} variant="secondary" mdxType="Radio" />
            <Radio name="basic" onChange={logValue} variant="secondary" mdxType="Radio" />
            <Radio checked name="basic" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary checked
            </Radio>
            <Radio name="basic" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary unchecked
            </Radio>
            <Radio checked disabled name="basic" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary checked disabled
            </Radio>
            <Radio disabled name="basic" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "full-width-labeled-radio-button"
    }}>{`Full width labeled radio button`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Flex direction=\"column\" style={{ width: 240 }}>\n    <Rhythm my={2}>\n      <Radio checked full name=\"labeled-radio\" onChange={logValue}>\n        Super fantastic label\n      </Radio>\n      <Radio full reverse name=\"labeled-radio\" onChange={logValue}>\n        Super fantastic label\n      </Radio>\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="column" style={{
          width: 240
        }} mdxType="Flex">
      <Rhythm my={2} mdxType="Rhythm">
        <Radio checked full name="labeled-radio" onChange={logValue} mdxType="Radio">
          Super fantastic label
        </Radio>
        <Radio full reverse name="labeled-radio" onChange={logValue} mdxType="Radio">
          Super fantastic label
        </Radio>
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "danger-radio-button"
    }}>{`Danger radio button`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Flex wrap direction=\"row\">\n    <Rhythm m={2}>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio checked name=\"danger\" onChange={logValue} validity=\"danger\">\n            Primary checked\n          </Radio>\n          <Radio name=\"danger\" onChange={logValue} validity=\"danger\">\n            Primary unchecked\n          </Radio>\n          <Radio\n            checked\n            disabled\n            name=\"danger\"\n            onChange={logValue}\n            validity=\"danger\"\n          >\n            Primary checked disabled\n          </Radio>\n          <Radio disabled name=\"danger\" onChange={logValue} validity=\"danger\">\n            Primary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio\n            checked\n            name=\"danger\"\n            onChange={logValue}\n            validity=\"danger\"\n            variant=\"secondary\"\n          >\n            Secondary checked\n          </Radio>\n          <Radio\n            name=\"danger\"\n            onChange={logValue}\n            validity=\"danger\"\n            variant=\"secondary\"\n          >\n            Secondary unchecked\n          </Radio>\n          <Radio\n            checked\n            disabled\n            name=\"danger\"\n            onChange={logValue}\n            validity=\"danger\"\n            variant=\"secondary\"\n          >\n            Secondary checked disabled\n          </Radio>\n          <Radio\n            disabled\n            name=\"danger\"\n            onChange={logValue}\n            validity=\"danger\"\n            variant=\"secondary\"\n          >\n            Secondary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap direction="row" mdxType="Flex">
      <Rhythm m={2} mdxType="Rhythm">
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked name="danger" onChange={logValue} validity="danger" mdxType="Radio">
              Primary checked
            </Radio>
            <Radio name="danger" onChange={logValue} validity="danger" mdxType="Radio">
              Primary unchecked
            </Radio>
            <Radio checked disabled name="danger" onChange={logValue} validity="danger" mdxType="Radio">
              Primary checked disabled
            </Radio>
            <Radio disabled name="danger" onChange={logValue} validity="danger" mdxType="Radio">
              Primary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked name="danger" onChange={logValue} validity="danger" variant="secondary" mdxType="Radio">
              Secondary checked
            </Radio>
            <Radio name="danger" onChange={logValue} validity="danger" variant="secondary" mdxType="Radio">
              Secondary unchecked
            </Radio>
            <Radio checked disabled name="danger" onChange={logValue} validity="danger" variant="secondary" mdxType="Radio">
              Secondary checked disabled
            </Radio>
            <Radio disabled name="danger" onChange={logValue} validity="danger" variant="secondary" mdxType="Radio">
              Secondary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-radio-button"
    }}>{`Contrast radio button`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper contrast>\n  <Flex wrap direction=\"row\">\n    <Rhythm m={2}>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio checked contrast name=\"contrast\" onChange={logValue}>\n            Primary checked\n          </Radio>\n          <Radio contrast name=\"contrast\" onChange={logValue}>\n            Primary unchecked\n          </Radio>\n          <Radio\n            checked\n            contrast\n            disabled\n            name=\"contrast\"\n            onChange={logValue}\n          >\n            Primary checked disabled\n          </Radio>\n          <Radio contrast disabled name=\"contrast\" onChange={logValue}>\n            Primary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n      <Flex direction=\"column\">\n        <Rhythm my={2}>\n          <Radio\n            checked\n            contrast\n            name=\"contrast\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary checked\n          </Radio>\n          <Radio\n            contrast\n            name=\"contrast\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary unchecked\n          </Radio>\n          <Radio\n            checked\n            contrast\n            disabled\n            name=\"contrast\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary checked disabled\n          </Radio>\n          <Radio\n            contrast\n            disabled\n            name=\"contrast\"\n            onChange={logValue}\n            variant=\"secondary\"\n          >\n            Secondary unchecked disabled\n          </Radio>\n        </Rhythm>\n      </Flex>\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Flex wrap direction="row" mdxType="Flex">
      <Rhythm m={2} mdxType="Rhythm">
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked contrast name="contrast" onChange={logValue} mdxType="Radio">
              Primary checked
            </Radio>
            <Radio contrast name="contrast" onChange={logValue} mdxType="Radio">
              Primary unchecked
            </Radio>
            <Radio checked contrast disabled name="contrast" onChange={logValue} mdxType="Radio">
              Primary checked disabled
            </Radio>
            <Radio contrast disabled name="contrast" onChange={logValue} mdxType="Radio">
              Primary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
        <Flex direction="column" mdxType="Flex">
          <Rhythm my={2} mdxType="Rhythm">
            <Radio checked contrast name="contrast" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary checked
            </Radio>
            <Radio contrast name="contrast" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary unchecked
            </Radio>
            <Radio checked contrast disabled name="contrast" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary checked disabled
            </Radio>
            <Radio contrast disabled name="contrast" onChange={logValue} variant="secondary" mdxType="Radio">
              Secondary unchecked disabled
            </Radio>
          </Rhythm>
        </Flex>
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h1 {...{
      "id": ""
    }}><br /></h1>
    <h1 {...{
      "id": "radiogroup"
    }}>{`RadioGroup`}</h1>
    <h2 {...{
      "id": "example-radios-values"
    }}>{`Example `}<inlineCode parentName="h2">{`radios`}</inlineCode>{` values`}</h2>
    <pre><code parentName="pre" {...{}}>{`[{
  id: "radio-group-item1",
  value: "one",
  label: 'Super fantastic label one',
}, {
  id: "radio-group-item2",
  value: "two",
  label: 'Super fantastic label two',
}, {
  id: "radio-group-item3",
  value: "three",
  label: 'Super fantastic label three',
}]
`}</code></pre>
    <h2 {...{
      "id": "stacked-group"
    }}>{`Stacked group`}</h2>
    <Playground __position={5} __code={'<FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n  <RadioGroup\n    layout=\"stacked\"\n    legend=\"Super fantastic label\"\n    name=\"stacked-radio-group\"\n    onChange={logValue}\n    radios={radioGroup}\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
    <RadioGroup layout="stacked" legend="Super fantastic label" name="stacked-radio-group" onChange={logValue} radios={radioGroup} mdxType="RadioGroup" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "inline-group"
    }}>{`Inline group`}</h2>
    <Playground __position={6} __code={'<FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n  <RadioGroup\n    layout=\"inline\"\n    legend=\"Super fantastic label\"\n    name=\"inline-radio-group\"\n    onChange={logValue}\n    radios={radioGroup}\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
    <RadioGroup layout="inline" legend="Super fantastic label" name="inline-radio-group" onChange={logValue} radios={radioGroup} mdxType="RadioGroup" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "inline-secondary-group"
    }}>{`Inline secondary group`}</h2>
    <Playground __position={7} __code={'<FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n  <RadioGroup\n    layout=\"inline\"\n    legend=\"Super fantastic label\"\n    name=\"inline-secondary-radio-group\"\n    onChange={logValue}\n    radios={radioGroup}\n    variant=\"secondary\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
    <RadioGroup layout="inline" legend="Super fantastic label" name="inline-secondary-radio-group" onChange={logValue} radios={radioGroup} variant="secondary" mdxType="RadioGroup" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "custom-group-legend"
    }}>{`Custom group legend`}</h2>
    <Playground __position={8} __code={'<FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n  <RadioGroup\n    layout=\"inline\"\n    legend={\n      <Typography\n        as=\"legend\"\n        style={{ marginBottom: \'8px\' }}\n        variants=\"medium-caps\"\n      >\n        Super fantastic legend\n      </Typography>\n    }\n    name=\"custom-radio-group\"\n    onChange={logValue}\n    radios={radioGroup}\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
    <RadioGroup layout="inline" legend={<Typography as="legend" style={{
          marginBottom: '8px'
        }} variants="medium-caps" mdxType="Typography">
          Super fantastic legend
        </Typography>} name="custom-radio-group" onChange={logValue} radios={radioGroup} mdxType="RadioGroup" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "no-group-legend"
    }}>{`No group legend`}</h2>
    <Playground __position={9} __code={'<FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n  <RadioGroup\n    layout=\"stacked\"\n    name=\"unlabeled-radio-group\"\n    onChange={logValue}\n    radios={radioGroup}\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
    <RadioGroup layout="stacked" name="unlabeled-radio-group" onChange={logValue} radios={radioGroup} mdxType="RadioGroup" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={10} __code={'<Looper\n  list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n  render={level => (\n    <FormComponentDemo initialValue=\"one\" property=\"value\" type=\"radio\">\n      <NotifiedRadioGroup\n        layout=\"inline\"\n        legend=\"Super fantastic label\"\n        level={level}\n        name={`notified-radio-group-${level}`}\n        notification={`This is an example ${level} notification.`}\n        onChange={logValue}\n        radios={radioGroup}\n      />\n    </FormComponentDemo>\n  )}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <FormComponentDemo initialValue="one" property="value" type="radio" mdxType="FormComponentDemo">
        <NotifiedRadioGroup layout="inline" legend="Super fantastic label" level={level} name={`notified-radio-group-${level}`} notification={`This is an example ${level} notification.`} onChange={logValue} radios={radioGroup} mdxType="NotifiedRadioGroup" />
      </FormComponentDemo>} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped py={3}>\n        <Radio checked onChange={logValue} size={size}>\n          Lorem ipsum dolor sit amet\n        </Radio>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Looper,
      Rhythm,
      Typography,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Radio,
      RadioGroup,
      NotifiedRadioGroup,
      sizes,
      radioGroup,
      logValue,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped py={3} mdxType="Rhythm">
          <Radio checked onChange={logValue} size={size} mdxType="Radio">
            Lorem ipsum dolor sit amet
          </Radio>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "radio"
    }}>{`Radio`}</h3>
    <Props of={Radio} mdxType="Props" />
    <h3 {...{
      "id": "radiogroup-1"
    }}>{`RadioGroup`}</h3>
    <Props of={RadioGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      